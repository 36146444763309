import styles from './SettingNotificationModal.module.css';


const SettingNotificationModal = ({setUserModalPage, perm}) => {
      const handleButtonClicked = () => {
            if(!perm.notificationPerm)
                  setUserModalPage(3);
      };

      return (
            <div className={styles.reviewmodal2}>
                  <div className={styles.loginframe}>
                        <div className={styles.headerframeParent}>
                              <div className={styles.headerframe}>
                                    <div className={styles.div}>알림 설정</div>
                              </div>
                              <div className={styles.contentsframe}>
                                    <div className={styles.captionframe} onClick={()=>handleButtonClicked()}>
                                          <div className={styles.div1}>알림 권한</div>
                                          <div className={styles.iconcheckedFilledParent}>
                                                <img className={perm.notificationPerm ? styles.iconcheckedFilled : styles.iconchecked} alt="" src="images/checked_filled.svg" />
                                                <div className={perm.notificationPerm ? styles.div2 : styles.div2Disabled}>{perm.notificationPerm ? "허용":"허용 안 됨"}</div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      );
};

export default SettingNotificationModal;