import { useEffect, useState } from 'react';
import styles from './VerifyTutorialModal.module.css';
import AddedHotpleItem from '../../../User/AddedHotple/AddedHotpleItem';

const VerifyTutorialModal = ({myHotples, updateHotpleInfo, setInfoModalPage}) => {
      const [hotple, setHotple] = useState(null);

      useEffect(()=>{
            const latestHotple = myHotples.hotples[myHotples.hotples.length-1];
            latestHotple.need_verify = true;
            setHotple(latestHotple);
      },[]);

      useEffect(()=>{
            if(hotple)
                  setInfoModalPage(0);
      },[myHotples]);

      return (
            <div className={styles.container}>
                  <div className={styles.header}>
                        <div className={styles.contents}>
                              <b className={styles.title}>핫플 검토 튜토리얼</b>
                              <div className={styles.innerContents}>
                                    <div className={styles.description}>
                                          <p className={styles.a}>
                                                <b className={styles.a1}>AI도 간혹 실수할 수 있어요..!</b>
                                          </p>
                                          <p className={styles.a}>
                                                <span className={styles.span1}>등록된 장소 정보가 정확한 지</span>
                                                <br/>
                                                <b className={styles.a1}>검토 필요</b>
                                                <span className={styles.span1}> 를 눌러 확인 부탁드려요😉</span>
                                          </p>
                                    </div>
                                    {hotple &&
                                          <AddedHotpleItem key={hotple.hotple_id} hotple={myHotples.hotples.find(h => h.hotple_id == hotple.hotple_id)} updateHotpleInfo={updateHotpleInfo}/>
                                    }
                              </div>
                        </div>
                  </div>
            </div>
      );
};

export default VerifyTutorialModal;