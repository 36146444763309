import { useEffect, useRef, useState } from 'react';
import styles from './ReviewHotpleModal2.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import ko from "date-fns/locale/ko";
import DetailHeader from '../DetailHeader/DetailHeader';


const ReviewHotpleModal2 = ({setHotpleModalPage, editedHotple, updateHotpleField, originalHotple, updateHotpleInfo, account, marginTop, isEditRequest=false}) => {
      const [startDate, setStartDate] = useState(null);
      const [endDate, setEndDate] = useState(null);
      const [beforeInfo, setBeforeInfo] = useState(null);
      const datePickerRef1 = useRef(null); // 첫 번째 DatePicker용 ref
      const datePickerRef2 = useRef(null); // 두 번째 DatePicker용 ref

      const handleCancelButtonClick = () => {
            updateHotpleField("caption", beforeInfo.caption);
            updateHotpleField("tags", beforeInfo.tags);
            updateHotpleField("start_date", beforeInfo.start_date);
            updateHotpleField("end_date", beforeInfo.end_date);
            updateHotpleField("has_period", beforeInfo.has_period);
            if(isEditRequest)
                  setHotpleModalPage(21);
            else
                  setHotpleModalPage(11);
      };

      const handleEditButtonClick = () => {
            if(editedHotple.caption==''||editedHotple.tags[0]==''||editedHotple.tags[1]==''||editedHotple.tags[2]=='')
                  return;
            
            if(isEditRequest)
                  setHotpleModalPage(21);
            else
                  setHotpleModalPage(11);
      };

      const clearDate1 = () => {
            datePickerRef1.current.setOpen(false); // 첫 번째 DatePicker 팝업 닫기
            datePickerRef1.current.clear(); // 첫 번째 DatePicker 날짜 초기화
      };
      
      const clearDate2 = () => {
            datePickerRef2.current.setOpen(false); // 두 번째 DatePicker 팝업 닫기
            datePickerRef2.current.clear(); // 두 번째 DatePicker 날짜 초기화
      };

      function getTextWidth(text, font) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.font = font;
            return context.measureText(text).width+2;
      }

      const handleNextButtonClick = async () => {
            if(editedHotple.caption==''||editedHotple.tags[0]==''||editedHotple.tags[1]==''||editedHotple.tags[2]=='')
                  return;

            setHotpleModalPage(3)
      };
      
      useEffect(() => {
            updateHotpleField("start_date",startDate ? format(startDate, 'yyyy-MM-dd HH:mm'):null);
            updateHotpleField("has_period", startDate!=null || endDate!=null);
      },[startDate]);
      useEffect(() => {
            updateHotpleField("end_date",endDate ? format(endDate, 'yyyy-MM-dd HH:mm'):null);
            updateHotpleField("has_period", startDate!=null || endDate!=null);
      },[endDate]);


      useEffect(() => {
            if(!originalHotple.need_verify)
                  setBeforeInfo(editedHotple);

            const tag1Input = document.getElementById('tag1Input');
            const tag2Input = document.getElementById('tag2Input');
            const tag3Input = document.getElementById('tag3Input');

            tag1Input.addEventListener('input', function () {
                  const font = window.getComputedStyle(tag1Input).font;  // 현재 input의 폰트 스타일을 가져오기
                  const textWidth = getTextWidth(this.value, font);  // 입력된 텍스트의 실제 길이를 계산
                  this.style.width = textWidth + 'px';  // 텍스트 길이에 맞춰 width 조정
            });
            tag2Input.addEventListener('input', function () {
                  const font = window.getComputedStyle(tag2Input).font;  // 현재 input의 폰트 스타일을 가져오기
                  const textWidth = getTextWidth(this.value, font);  // 입력된 텍스트의 실제 길이를 계산
                  this.style.width = textWidth + 'px';  // 텍스트 길이에 맞춰 width 조정
            });
            tag3Input.addEventListener('input', function () {
                  const font = window.getComputedStyle(tag3Input).font;  // 현재 input의 폰트 스타일을 가져오기
                  const textWidth = getTextWidth(this.value, font);  // 입력된 텍스트의 실제 길이를 계산
                  this.style.width = textWidth + 'px';  // 텍스트 길이에 맞춰 width 조정
            });

            const initialFont = window.getComputedStyle(tag1Input).font;
            const initialWidth1 = getTextWidth(editedHotple.tags[0], initialFont);
            const initialOriWidth1 = getTextWidth(originalHotple.tags[0], initialFont);
            tag1Input.style.width = initialWidth1 + 'px';
            tag1Input.style.minWidth = initialOriWidth1 + 'px';

            const initialWidth2 = getTextWidth(editedHotple.tags[1], initialFont);
            const initialOriWidth2 = getTextWidth(originalHotple.tags[1], initialFont);
            tag2Input.style.width = initialWidth2 + 'px';
            tag2Input.style.minWidth = initialOriWidth2 + 'px';

            const initialWidth3 = getTextWidth(editedHotple.tags[2], initialFont);
            const initialOriWidth3 = getTextWidth(originalHotple.tags[2], initialFont);
            tag3Input.style.width = initialWidth3 + 'px';
            tag3Input.style.minWidth = initialOriWidth3 + 'px';

            setTimeout(() => {
                  if(editedHotple){
                        if(editedHotple.start_date)
                              setStartDate(new Date(editedHotple.start_date.replace(' ', 'T')));
                        if(editedHotple.end_date)
                              setEndDate(new Date(editedHotple.end_date.replace(' ', 'T')));
                  }
            }, 100);
      }, []);

      return (
            <div className={styles.reviewmodal2} style={{maxHeight: `calc(100dvh - ${marginTop + 24}px)`, marginTop: `${marginTop}px`}}>
            <div className={styles.loginframe}>
                  <div className={styles.headerframe}>
                        
                        {originalHotple.need_verify ?
                              <b className={styles.b}>소개/태그는 적절한가요?</b>
                        :
                              <b className={styles.b}>어떻게 수정할까요?</b>
                        }
                        <a className={styles.instagrambutton} href={originalHotple.media_url} target="blank" rel="noopener noreferrer">
                              게시글 확인하고 오기 →
                        </a>

                        {account.isAdmin &&
                              <DetailHeader hotple={editedHotple} updateHotpleField={updateHotpleField} updateHotpleInfo={updateHotpleInfo}/>
                        }
                        {originalHotple.media_caption &&
                              <div className={styles.captionFrame}>
                                    <div className={styles.mediaCaption1}>게시글 본문</div>
                                    <div className={styles.mediaCaption} style={{ whiteSpace: 'pre-line', lineHeight: '1.1' }}>{originalHotple.media_caption.replace(/\n{3,}/g, '\n\n')}</div>
                              </div>
                        }
                  </div>
                  <div className={styles.contentsframe}>
                        <div className={styles.captionframe}>
                              <div className={styles.div}>소개 :</div>
                              <div className={styles.nameinput}>
                                    <img
                                          className={styles.iconpen}
                                          alt=""
                                          src="images/pen.svg"
                                    />
                                    <input
                                          type="text"
                                          id="captionInput"
                                          className={styles.captioninput}
                                          value={editedHotple.caption}
                                          onChange={(e) => updateHotpleField("caption",e.target.value)}
                                          placeholder={originalHotple.caption}
                                          autoComplete="off"
                                    />
                              </div>
                        </div>
                        <div className={styles.tagsframe}>
                              <div className={styles.div1}>태그 :</div>
                              <div className={styles.tagsinputframe}>
                                    <div className={styles.tag1}>
                                          <img className={styles.iconpen} alt="" src="images/pen.svg" />
                                          <input
                                                type="text"
                                                id="tag1Input"
                                                className={styles.tag1Input}
                                                value={editedHotple.tags[0]}
                                                onChange={(e) => updateHotpleField("tags",e.target.value,0)}
                                                placeholder={originalHotple.tags[0]}
                                                autoComplete="off"
                                          />
                                    </div>
                                    <div className={styles.tag1}>
                                          <img className={styles.iconpen} alt="" src="images/pen.svg" />
                                          <input
                                                type="text"
                                                id="tag2Input"
                                                className={styles.tag1Input}
                                                value={editedHotple.tags[1]}
                                                onChange={(e) => updateHotpleField("tags",e.target.value,1)}
                                                placeholder={originalHotple.tags[1]}
                                                autoComplete="off"
                                          />
                                    </div>
                                    <div className={styles.tag1}>
                                          <img className={styles.iconpen} alt="" src="images/pen.svg" />
                                          <input
                                                type="text"
                                                id="tag3Input"
                                                className={styles.tag1Input}
                                                value={editedHotple.tags[2]}
                                                onChange={(e) => updateHotpleField("tags",e.target.value,2)}
                                                placeholder={originalHotple.tags[2]}
                                                autoComplete="off"
                                          />
                                    </div>
                              </div>
                        </div>
                        <div className={styles.bottomframe}>
                              <div className={styles.text}>
                                    <img
                                          className={styles.iconinfo}
                                          alt=""
                                          src="images/info.svg"
                                    />
                                    <div className={styles.div5}>
                                          <span>{`팝업/축제/공연/전시 등 `}</span>
                                          <span className={styles.span}>
                                                진행 기간이 있는 핫플에만 설정
                                          </span>
                                    </div>
                              </div>
                              <div className={styles.periodframe}>
                                    <div className={styles.div1}>기간 :</div>
                                    <div className={styles.periodinputframe}>                                          
                                          <DatePicker
                                                ref={datePickerRef1}
                                                className={styles.periodinput1}
                                                selected={startDate}
                                                onChange={(date)=>{setStartDate(date)}}
                                                showTimeSelect
                                                dateFormat="yyyy/MM/dd HH:mm"
                                                timeFormat="HH:mm"
                                                timeIntervals={30}
                                                locale={ko}
                                                placeholderText="시작일 없음"
                                                onFocus={e => e.target.blur()}
                                                renderCustomHeader={({
                                                date,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                                }) => (
                                                      <div>
                                                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                                                            <div onClick={clearDate1} className={styles.noPeriodButton}>시작일은 따로 없어요!</div>
                                                      </div>
                                          
                                                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <div className={styles.monthButton} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                            {'<'}
                                                      </div>
                                                      <span className={styles.monthText}>
                                                            {format(date, 'yyyy년 MM월', { locale: ko })}
                                                      </span>
                                                            <div className={styles.monthButton} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                            {'>'}
                                                            </div>
                                                      </div>
                                                    </div>
                                                )}
                                                withPortal
                                          />

                                          <div className={styles.div8}>~</div>

                                          <DatePicker
                                                ref={datePickerRef2}
                                                className={styles.periodinput1}
                                                selected={endDate}
                                                onChange={(date)=>{setEndDate(date)}}
                                                showTimeSelect
                                                dateFormat="yyyy/MM/dd HH:mm"
                                                timeFormat="HH:mm"
                                                timeIntervals={30}
                                                locale={ko}
                                                placeholderText="종료일 없음"
                                                onFocus={e => e.target.blur()}
                                                renderCustomHeader={({
                                                date,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                                }) => (
                                                      <div>
                                                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                                                            <div onClick={clearDate2} className={styles.noPeriodButton}>종료일은 따로 없어요!</div>
                                                      </div>
                                          
                                                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <div className={styles.monthButton} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                            {'<'}
                                                      </div>
                                                      <span className={styles.monthText}>
                                                            {format(date, 'yyyy년 MM월', { locale: ko })}
                                                      </span>
                                                            <div className={styles.monthButton} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                            {'>'}
                                                            </div>
                                                      </div>
                                                </div>
                                                )}
                                                withPortal
                                          />
                                    </div>
                              </div>
                        </div>
                  </div>
                  {originalHotple.need_verify?
                  <div className={
                        editedHotple.caption==''||editedHotple.tags[0]==''||editedHotple.tags[1]==''||editedHotple.tags[2]==''
                        ? styles.nextButton
                        : styles.nextButtonActive}
                        disabled={editedHotple.caption==''||editedHotple.tags[0]==''||editedHotple.tags[1]==''||editedHotple.tags[2]==''}
                        onClick={handleNextButtonClick}>
                        <div className={styles.text1}>거의 다왔어요! (2/3)</div>
                  </div>
                  :
                  <div className={styles.editbuttonsframe}>
                        <div className={styles.cancelButton} onClick={()=>handleCancelButtonClick()}>
                              <div className={styles.cancellabel}>돌아가기</div>
                        </div>
                        <div className={editedHotple.caption==''||editedHotple.tags[0]==''||editedHotple.tags[1]==''||editedHotple.tags[2]==''
                        ?styles.editButtonDisabled
                        :styles.editButton}
                        disabled={editedHotple.caption==''||editedHotple.tags[0]==''||editedHotple.tags[1]==''||editedHotple.tags[2]==''}
                        onClick={()=>handleEditButtonClick()}>
                              <div className={styles.edittext}>수정하기</div>
                        </div>
                  </div>
                  }
            </div>
      </div>);
};

export default ReviewHotpleModal2;