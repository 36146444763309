import styles from './UserHeader.module.css';


const UserHeader = ({setUserModalPage, setAuthModalPage, setInfoModalPage, setTutorialModalPage, account, setActiveMenu, setSelectedOption, myHotples, fcmToken, setModalProps}) => {
    const handleSettingClicked = async () => {
		setModalProps({fcmToken: fcmToken});
		setInfoModalPage(51);
    };

	const handleConnectInstagram = async () => {
		setAuthModalPage(3);
	}

	const handleLevelClicked = async () => {
		setUserModalPage(4);
	}

	const handleHotpleInfoClicked = (filter) => {
		setActiveMenu('my');
		setSelectedOption(prev => ({ ...prev, filter: filter }));
	}

	const handleInstagramHandleClicked = () => {
		if(account.instagramUsername)
			window.open(`https://instagram.com/${account.instagramUsername}/`, '_blank');
		else
			handleConnectInstagram();
	}

  	return (
    		<div className={styles.userheader}>
				<div className={styles.infoFrame}>
					<div className={styles.frameParent}>
						<div className={styles.frameGroup}>
								<div className={styles.parent}>
									<div className={styles.div4}>계정</div>
									<div className={styles.iconfireParent}>
											<img className={styles.iconfire} alt="" src="images/fire.svg" />
											<div className={styles.div}>{account.hotpleId}</div>
									</div>
								</div>
								<div className={styles.iconinstagramParent}>
									<img className={styles.iconinstagram} alt="" src="images/instagram.svg" />
									<div className={styles.instagramaccountid} onClick={handleInstagramHandleClicked}>{account.instagramUsername!=null?account.instagramUsername:'미연결'}</div>
								</div>
						</div>
						<div className={styles.div2} onClick={()=>handleSettingClicked()}>설정</div>
					</div>

					<div className={styles.frameParent2} onClick={handleLevelClicked}>
						<div className={styles.frameGroup}>
								<div className={styles.parentRail}>
									<div className={styles.level}>Lv.{account.level}</div>
									<div className={styles.rail}>
										<div className={styles.train}
											style={{width: `${(account.xp.level_xp / account.xp.level_max) * 100}%`}} />
										<div className={styles.xp}>{account.xp.level_xp}XP / {account.xp.level_max}XP</div>
									</div>
								</div>
						</div>
					</div>
				</div>

      			<div className={styles.hotpleinfos}>
        				<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('all')}>
          					<div className={styles.pick1}>내 핫플</div>
          					<div className={styles.myhotplenum}>{myHotples.hotples ? myHotples.hotples.length : 0}개</div>
        				</div>
						<div className={styles.verticalDivider}/>
        				<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('my_pick')}>
          					<div className={styles.pick2}>내 PICK</div>
          					<div className={styles.myhotplenum2}>{myHotples.hotples ? myHotples.hotples.filter(hotple => hotple.taggedBy==null).length : 0}개</div>
        				</div>
						<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('all')}>
          					<div className={styles.pick2}>친구 태그</div>
          					<div className={styles.myhotplenum2}>{myHotples.hotples ? myHotples.hotples.filter(hotple => hotple.taggedBy).length : 0}개</div>
        				</div>
						<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('need_verify')}>
          					<div className={styles.pick2}>검토 필요</div>
          					<div className={styles.myhotplenum2}>{myHotples.hotples ? myHotples.hotples.filter(hotple => hotple.need_verify).length : 0}개</div>
        				</div>
      			</div>

				{account.tutorial_phase<7 &&
				<div className={styles.instagramFrame}>
					<div className={styles.connectinstagrambutton3}  onClick={()=>setTutorialModalPage(1)}>
						<div className={styles.text2}><span className={styles.iconfireParent2}>HOTPLE</span> 튜토리얼</div>
						<div className={styles.point}>+2500XP</div>
					</div>
					<div className='buttonBadge'>튜토리얼 진행 중!</div>
				</div>
				}
    		</div>);
};

export default UserHeader;
