import { josa } from '../../../../util';
import styles from './DeleteHotpleModal.module.css';


const DeleteHotpleModal = ({setUserModalPage, modalProps, updateHotpleInfo, marginTop}) => {
    const handleDeleteButtonClicked = () =>{
        const updatedHotple = {
			...modalProps.hotple,
			is_pick: !modalProps.hotple.is_pick,
			mention_count: modalProps.hotple.mention_count + (!modalProps.hotple.is_pick ? 1 : -1)
		};

        updateHotpleInfo((modalProps.is_remove? 'deleteHotple' : 'deleteMyHotple'), updatedHotple);
    };

  	return (
        <div className={styles.container} style={{maxHeight: `calc(100dvh - ${marginTop + 24}px)`, marginTop: `${marginTop}px`}}>
            <div className={styles.header}>
                <div className={styles.contents}>
                    <b className={styles.title}>
                            {modalProps.hotple.name &&
                            <p className={styles.p}>
                                <span>'{modalProps.hotple.name}'</span>
                                <span className={styles.span}> {josa(modalProps.hotple.name,'을를')}</span>
                            </p>
                            }
                            <p className={styles.p1}>
                                <span className={styles.span1}>{modalProps.is_remove?"핫플":"내 핫플 "}</span>
                                <span>에서 삭제하시겠어요?</span>
                            </p>
                    </b>
                    <div className={styles.buttons}>
                        <div className={styles.cancleButton} onClick={()=>setUserModalPage(0)}>
                                <div className={styles.text}>취소</div>
                        </div>
                        <div className={styles.deleteButton} onClick={()=>handleDeleteButtonClicked()}>
                                <div className={styles.text}>삭제하기</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteHotpleModal;