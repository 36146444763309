import { BrowserView, isBrowser, MobileView } from 'react-device-detect';
import styles from './DetailHeader.module.css';
import api from '../../../../api';
import { getFiles, getImageFile } from '../../../../util';


const DetailHeader = ({ hotple, updateHotpleField, updateHotpleInfo }) => {
    const isVideo = (url) => {
        return url.endsWith('.mp4');
    };

    const handleDeleteImageButtonClick = async (idx) => {
        try {
            const confirmDelete = window.confirm(`이 이미지를 영구 삭제하시겠습니까?`);
    
            if (confirmDelete) {
                const response = await api.delete(`/admin/media/${hotple.media_id}/images/${idx}`);
    
                updateHotpleField("contents_url", response.data.contents_url);
                updateHotpleField("preview_url", response.data.preview_url);

                updateHotpleInfo("updateHotpleImagesFromList", {...hotple, contents_url: response.data.contents_url, preview_url: response.data.preview_url});
            }
        } catch (error) {
            console.error('Error deleting notice:', error);
        }
    };

    const handleAddImagesButtonClicked = async (idx) => {
        try {
            let selectedFiles;
    
            if (idx === 0) {
                // preview용 파일 1개 선택
                selectedFiles = [await getImageFile()]; // 파일 하나만 배열로 감싸 반환
            } else {
                // contents용 여러 개의 파일 선택
                selectedFiles = await getFiles();
            }
    
            // 선택한 파일의 수를 사용자에게 확인
            const isConfirmed = window.confirm(`${selectedFiles.length}개의 이미지를 추가하시겠습니까?`);
            if (!isConfirmed) return;
    
            // FormData에 파일 추가
            const formData = new FormData();
            selectedFiles.forEach((file, i) => {
                formData.append('images', file.file || file); // preview용과 contents용 파일 모두 처리
            });
    
            // API 호출
            const response = await api.put(`/admin/media/${hotple.media_id}/images/${idx}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            updateHotpleField("contents_url", response.data.contents_url);
            updateHotpleField("preview_url", response.data.preview_url);

            updateHotpleInfo("updateHotpleImagesFromList", {...hotple, contents_url: response.data.contents_url, preview_url: response.data.preview_url});
            
        } catch (error) {
            console.error('이미지 추가 중 오류 발생:', error);
        }
    };
    

    const topButtons = (idx) => {
        return (
            <div className={styles.topFrame}>
                {idx!=0 ? <div className={styles.plusLine} onClick={()=>handleAddImagesButtonClicked(idx)}>+</div> : <div className={styles.plusLineThumb}>+</div>}
                <span className={idx==0?styles.imageTypePreview:styles.imageTypeContent}>{idx==0?"썸네일":`${idx}`}</span>
                <img className={styles.iconcloseLine} alt="" src="images/close_line.svg" onClick={()=>handleDeleteImageButtonClick(idx)} />
            </div>
        )
    }

    return (
        <div className={styles.frameParent}>
            <div className={styles.imageScroll}>
                <div className={styles.frameGroup}>
                    {hotple.preview_url ?
                        <div className={styles.vertical}>
                            {topButtons(0)}
                            <img className={isBrowser ? styles.image : styles.imageMobile} alt="" src={hotple.preview_url} />
                        </div>
                    :
                        <div className={styles.nextButtonActive} onClick={()=>handleAddImagesButtonClicked(0)}>+<br/>썸네일<br/>추가</div>
                    }
                    {hotple.contents_url &&
                        hotple.contents_url.map((url, idx) =>
                            isVideo(url) ? (
                                <div className={styles.vertical}>
                                    {topButtons(idx+1)}
                                    <video key={url} className={styles.image} controls>
                                        <source src={url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ) : (
                                <div className={styles.vertical}>
                                    {topButtons(idx+1)}
                                    <img key={url} className={isBrowser ? styles.image : styles.imageMobile} alt="" src={url} />
                                </div>
                            )
                        )}
                        <div className={styles.nextButtonActive} onClick={()=>handleAddImagesButtonClicked(hotple.contents_url?hotple.contents_url.length+1:1)}>+<br/>이미지<br/>추가</div>
                </div>
            </div>
        </div>
    );
};

export default DetailHeader;