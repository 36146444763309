import { useEffect, useState } from 'react';
import styles from './AIScrapFriendTutorialModal.module.css';

const AIScrapFriendTutorialModal = ({myHotples, setInfoModalPage}) => {
      const handleGoButtonClick = async () => {
            try {
                alert('맘에 드는 장소 게시글을 찾아, 친구와 함께 @hotple_kr을 태그하면 완료😊');

                window.open('https://www.instagram.com', '_blank');
            } catch (error) {
                console.error('Error handling go button click:', error);
            }
      };

      useEffect(()=>{
            if(myHotples.hotples.some(myHotple => (!myHotple.taggedBy && myHotple.comment?.includes("@"))))
                  setInfoModalPage(0);
      },[myHotples]);

      return (
            <div className={styles.container}>
                  <div className={styles.header}>
                        <div className={styles.contents}>
                              <b className={styles.title}>친구 태그 AI 스크랩 튜토리얼</b>
                              <div className={styles.innerContents}>
                                    <div className={styles.description}>
                                          <p className={styles.hotpleDm}>
                                                <b className={styles.hotple}>1. 인스타에서 맘에 드는 장소를 직접 찾아</b>
                                                <span className={styles.span}></span>
                                          </p>
                                          <p className={styles.a}>
                                                <b className={styles.a1}>2. 같이 가고 싶은 친구도 함께 태그!</b>
                                          </p>
                                    </div>
                                    <video
                                          className={styles.description1}
                                          src="https://hotple.s3.ap-northeast-2.amazonaws.com/usage/ai_scrap_friend.mp4"
                                          autoPlay 
                                          loop 
                                          muted 
                                          playsInline
                                    />
                                    <div className={styles.dmButton} onClick={() => handleGoButtonClick()}>
                                          <div className={styles.text}>친구 태그할 장소 찾으러 가기</div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      );
};

export default AIScrapFriendTutorialModal;