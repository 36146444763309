import styles from './InfoModal.module.css';
import UsageModal from './Usage/UsageModal';
import NoticeModal from './Notice/NoticeModal';
import UserMonitorModal from './UserMonitor/UserMonitorModal';
import EditNoticeModal from './EditNotice/EditNoticeModal';
import EditPeriodCategoryModal from './EditPeriodCategory/EditPeriodCategoryModal';
import InquiryMonitorModal from './InquiryMonitor/InquiryMonitorModal';
import SettingMainModal from './Setting/SettingMainModal';
import SettingAccountModal from './Setting/SettingAccountModal';
import SettingNotificationModal from './Setting/SettingNotificationModal';
import AIScrapTutorialModal from './Tutorial/AIScrap/AIScrapTutorialModal';
import AIScrapFriendTutorialModal from './Tutorial/AIScrapFriend/AIScrapFriendTutorialModal';
import VerifyTutorialModal from './Tutorial/Verify/VerifyTutorialModal';
import FinishTutorialModal from './Tutorial/Finish/FinishTutorialModal';
import { useEffect, useState } from 'react';

const InfoModal = ({infoModalPage, setInfoModalPage, setAuthModalPage, setUserModalPage, modalProps, setModalProps, setNotices, account, perm, platform, devicePosition, myHotples, updateHotpleInfo}) => {
    const [visible, setVisible] = useState(false);
    
    useEffect(() => {
        setVisible(true);
    }, []);
    
    const goSettingMainPage = () => {
        setInfoModalPage(51);
    };

  	return (
        <div className={`${styles.modalbackground} ${visible ? styles.visible : ''}`}>
            <div className={`${styles.modalcontainer} ${visible ? styles.visible : ''}`}>
                {infoModalPage==1 && <UsageModal setInfoModalPage={setInfoModalPage}/>}
                {infoModalPage==2 && <NoticeModal setInfoModalPage={setInfoModalPage} popupNoticeList={modalProps.popupNoticeList}/>}

                {infoModalPage==11 && <EditNoticeModal setInfoModalPage={setInfoModalPage} setNotices={setNotices}/>}
                {infoModalPage==12 && <UserMonitorModal setInfoModalPage={setInfoModalPage}/>}

                {infoModalPage==21 && <EditPeriodCategoryModal setInfoModalPage={setInfoModalPage}/>}

                {/* 31 */}

                {infoModalPage==41 && <InquiryMonitorModal setInfoModalPage={setInfoModalPage}/>}

                {infoModalPage==51 && <SettingMainModal setInfoModalPage={setInfoModalPage} platform={platform}/>}
                {infoModalPage==52 && <SettingAccountModal setInfoModalPage={setInfoModalPage} setAuthModalPage={setAuthModalPage} setModalProps={setModalProps} account={account} fcmToken={modalProps.fcmToken}/>}
                {infoModalPage==53 && <SettingNotificationModal setUserModalPage={setUserModalPage} perm={perm}/>}

                {infoModalPage==61 && <AIScrapTutorialModal devicePosition={devicePosition} myHotples={myHotples} setInfoModalPage={setInfoModalPage} setAuthModalPage={setAuthModalPage} account={account}/>}
                {infoModalPage==62 && <VerifyTutorialModal myHotples={myHotples} updateHotpleInfo={updateHotpleInfo} setInfoModalPage={setInfoModalPage}/>}
                {infoModalPage==63 && <AIScrapFriendTutorialModal myHotples={myHotples} setInfoModalPage={setInfoModalPage}/>}
                {infoModalPage==64 && <FinishTutorialModal setInfoModalPage={setInfoModalPage} account={account}/>}

                {infoModalPage!=12 && infoModalPage!=64 && 
                    <div className={styles.closebutton} onClick={()=>setInfoModalPage(0)}>
                        <img className={styles.iconcloseLine} alt="" src="images/close_line.svg" />
                    </div>
                }
                {(infoModalPage==52 || infoModalPage==53) &&
                    <div className={styles.backbutton} onClick={goSettingMainPage}>
                        <img className={styles.iconcloseLine} alt="" src="images/back.svg" />
                    </div>
                }
            </div>
        </div>
    );
};
    
export default InfoModal;