import api from '../../../../api';
import styles from './SettingMainModal.module.css';


const SettingMainModal = ({setInfoModalPage, platform}) => {
      const goInstagramMessage = () => {
            const flag = window.confirm("인스타 DM으로 '문의'를 입력하시면 문의를 진행할 수 있습니다 :)\n\n문의하러 가시겠어요?")

            if(flag)
                  window.open("https://ig.me/m/hotple_kr", '_blank', 'noopener,noreferrer');
      };
      
    return (
        <div className={styles.reviewmodal2}>
              <div className={styles.loginframe}>
                    <div className={styles.headerframeParent}>
                          <div className={styles.headerframe}>
                                <div className={styles.div}>설정 메인</div>
                          </div>
                          <div className={styles.contentsframe}>
                                <div className={styles.captionframe} onClick={()=>setInfoModalPage(52)}>
                                      <div className={styles.div1}>계정 설정</div>
                                      <img className={styles.iconbackAndroid} alt="" src="images/back_android.svg" />
                                </div>
                                {platform!='web' &&
                                    <div className={styles.captionframe1} onClick={()=>setInfoModalPage(53)}>
                                      <div className={styles.div1}>알림 설정</div>
                                      <img className={styles.iconbackAndroid} alt="" src="images/back_android.svg" />
                                    </div>
                                }
                          </div>
                    </div>
                    <div className={styles.headerframeParent}>
                          <div className={styles.headerframe}>
                                <div className={styles.div}>문의</div>
                          </div>
                          <div className={styles.contentsframe}>
                                <div className={styles.captionframe} onClick={goInstagramMessage}>
                                      <div className={styles.div1}>버그/오류 제보</div>
                                      <img className={styles.iconbackAndroid} alt="" src="images/back_android.svg" />
                                </div>
                                <div className={styles.captionframe1} onClick={goInstagramMessage}>
                                      <div className={styles.div1}>서비스 관련 문의</div>
                                      <img className={styles.iconbackAndroid} alt="" src="images/back_android.svg" />
                                </div>
                                <div className={styles.captionframe1} onClick={goInstagramMessage}>
                                      <div className={styles.div1}>광고/제휴 문의</div>
                                      <img className={styles.iconbackAndroid} alt="" src="images/back_android.svg" />
                                </div>
                          </div>
                    </div>
              </div>
        </div>
    );
};

export default SettingMainModal;