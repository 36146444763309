import styles from './MapHeader.module.css';


const MapHeader = ({activeMenu, selectedOption, setSelectedOption, mapHotples, mapLocation, setIsSearchMode, setInfoModalPage, setUserModalPage, periodHotpleGroup, marginTop, isLimited}) => {
	const handleLimitBadgeClicked = (event) => {
		event.stopPropagation();
		setUserModalPage(4);
	};

  	return (
    		<div style={{marginTop: `${marginTop}px`}} className={styles.mapHeader}>
      			<div className={styles.locationSearchBar} onClick={()=>setIsSearchMode(true)}>
						<div className={styles.div}>
							<div className={styles.div1}>{mapLocation.name || mapLocation.address}</div>
							<img className={styles.iconchevronBottom} alt="" src="images/chevron_bottom.svg" />
							<div className={styles.km}>핫플 {mapHotples.hotples.length}개</div>
							{activeMenu != 'my' && isLimited &&
								<div className={'buttonBadge'} style={{top:'6px',right:'12px'}} onClick={handleLimitBadgeClicked}>검색결과 제한됨</div>
							}
						</div>
        				<div className={styles.right}>
          					<div className={styles.div2}>
            						<img className={styles.iconsearch} alt="" src="images/search.svg" />
          					</div>
        				</div>
      			</div>
      			<div className={styles.categorybuttons}>
					<div className={styles.div3}>
						<div className={styles.list}>
							<div className={`${selectedOption.category === 'recommend' ? styles.buttonplaceCategorySelected : styles.buttonplaceCategory}`} onClick={() => setSelectedOption((prev)=>({...prev, category:'recommend'}))}>
								<img className={styles.iconstarFilled} alt="" src="images/star_filled.svg" />
								<div className={styles.label}>{activeMenu=='my' || activeMenu=='admin' ? "전체" : "추천"}</div>
							</div>
							<div className={`${selectedOption.category === 'restaurant' ? styles.buttonplaceCategorySelected : styles.buttonplaceCategory}`} onClick={() => setSelectedOption((prev)=>({...prev, category:'restaurant'}))}>
								<img className={styles.iconstarFilled} alt="" src="images/restaurant.svg" />
								<div className={styles.label}>음식점</div>
							</div>
							<div className={`${selectedOption.category === 'alcohol' ? styles.buttonplaceCategorySelected : styles.buttonplaceCategory}`} onClick={() => setSelectedOption((prev)=>({...prev, category:'alcohol'}))}>
								<img className={styles.iconstarFilled} alt="" src="images/alcohol.svg" />
								<div className={styles.label}>주점</div>
							</div>
							<div className={`${selectedOption.category === 'cafe' ? styles.buttonplaceCategorySelected : styles.buttonplaceCategory}`} onClick={() => setSelectedOption((prev)=>({...prev, category:'cafe'}))}>
								<img className={styles.iconstarFilled} alt="" src="images/cafe.svg" />
								<div className={styles.label}>카페/디저트</div>
							</div>
							<div className={`${selectedOption.category === 'popupStore' ? styles.buttonplaceCategorySelected : styles.buttonplaceCategory}`} onClick={() => setSelectedOption((prev)=>({...prev, category:'popupStore'}))}>
								<img className={styles.iconstarFilled} alt="" src="images/popupStore.svg" />
								<div className={styles.label}>팝업스토어</div>
							</div>
							<div className={`${selectedOption.category === 'activity' ? styles.buttonplaceCategorySelected : styles.buttonplaceCategory}`} onClick={() => setSelectedOption((prev)=>({...prev, category:'activity'}))}>
								<img className={styles.iconstarFilled} alt="" src="images/activity.svg" />
								<div className={styles.label}>오락/액티비티</div>
							</div>
							<div className={`${selectedOption.category === 'cultural' ? styles.buttonplaceCategorySelected : styles.buttonplaceCategory}`} onClick={() => setSelectedOption((prev)=>({...prev, category:'cultural'}))}>
								<img className={styles.iconstarFilled} alt="" src="images/smile.svg" />
								<div className={styles.label}>문화/예술</div>
							</div>
							<div className={`${selectedOption.category === 'nature' ? styles.buttonplaceCategorySelected : styles.buttonplaceCategory}`} onClick={() => setSelectedOption((prev)=>({...prev, category:'nature'}))}>
								<img className={styles.iconstarFilled} alt="" src="images/flower.svg" />
								<div className={styles.label}>자연/힐링</div>
							</div>
							{periodHotpleGroup.map((group)=>{return (
								<img key={group.name} className={styles.hotpleGroup} alt="" src={group.button_image_url} onClick={() => setSelectedOption((prev)=>({...prev, category:`@${group.name}`}))}/>
							)})}
							{activeMenu=='admin' &&
								<div className={styles.buttonplaceCategoryPeriod} onClick={() => setInfoModalPage(21)}>
									<div className={styles.label}>+ 기간 카테고리</div>
								</div>
							}
						</div>
					</div>
      			</div>
    		</div>);
};

export default MapHeader;
