import styles from './LevelModal.module.css';


const LevelModal = ({account, marginTop}) => {
    return (
        <div className={styles.logoutmodal} style={{maxHeight: `calc(100dvh - ${marginTop + 24}px)`, marginTop: `${marginTop}px`}} >
              <div className={styles.hedaer}>
                    <div className={styles.mainheaderParent}>
                    <div className={styles.mainheader}>
                                <span className={styles.mainheaderTxt}>
                                      <span>HOTPLE</span>
                                      <b className={styles.b}> 레벨 혜택</b>
                                </span>
                          </div>
                          <div className={styles.div}>추후, 더 다양한 혜택 추가 예정 😉</div>
                          <div className={styles.parent}>
                                <div className={styles.div1}>
                                      <div className={styles.div2}>레벨</div>
                                      <div className={styles.km}>핫플찾기 반경</div>
                                      <div className={styles.km}>노출 핫플 수</div>
                                      <div className={styles.xp}>레벨업 요구 XP</div>
                                </div>
                                <div className={styles.group}>
                                      <div className={account.level==0 ? styles.div9 : styles.div5}>
                                            <div className={styles.tagpoint}>
                                                  <div className={styles.p}>Lv.0</div>
                                            </div>
                                            <div className={styles.km}>~ 10km</div>
                                            <div className={styles.km}>~ 5개</div>
                                            <div className={styles.xp1}>가입 시 Lv.1</div>
                                            {account.level==0 &&
                                                <div className={'buttonBadge'} style={{top:'-10px',left:'-12px', right:'auto', fontSize: '9px', padding: '3px 6px'}}>현재</div>
                                            }
                                      </div>
                                      <div className={account.level==1 ? styles.div9 : styles.div5}>
                                            <div className={styles.tagpoint}>
                                                  <div className={styles.p}>Lv.1</div>
                                            </div>
                                            <div className={styles.km}>~ 20km</div>
                                            <div className={styles.km}>~ 10개</div>
                                            <div className={styles.xp1}>500 XP</div>
                                            {account.level==1 &&
                                                <div className={'buttonBadge'} style={{top:'-10px',left:'-12px', right:'auto', fontSize: '9px', padding: '3px 6px'}}>현재</div>
                                            }
                                      </div>
                                      <div className={account.level==2 ? styles.div9 : styles.div5}>
                                            <div className={styles.tagpoint}>
                                                  <div className={styles.p}>Lv.2</div>
                                            </div>
                                            <div className={styles.km}>~ 40km</div>
                                            <div className={styles.km}>~ 15개</div>
                                            <div className={styles.xp1}>1,000 XP</div>
                                            {account.level==2 &&
                                                <div className={'buttonBadge'} style={{top:'-10px',left:'-12px', right:'auto', fontSize: '9px', padding: '3px 6px'}}>현재</div>
                                            }
                                      </div>
                                      <div className={account.level==3 ? styles.div9 : styles.div5}>
                                            <div className={styles.tagpoint}>
                                                  <div className={styles.p}>Lv.3</div>
                                            </div>
                                            <div className={styles.km}>~ 60km</div>
                                            <div className={styles.km}>~ 20개</div>
                                            <div className={styles.xp1}>1,500 XP</div>
                                            {account.level==3 &&
                                                <div className={'buttonBadge'} style={{top:'-10px',left:'-12px', right:'auto', fontSize: '9px', padding: '3px 6px'}}>현재</div>
                                            }
                                      </div>
                                      <div className={account.level==4 ? styles.div9 : styles.div5}>
                                            <div className={styles.tagpoint}>
                                                  <div className={styles.p}>Lv.4</div>
                                            </div>
                                            <div className={styles.km}>~ 100km</div>
                                            <div className={styles.km}>~ 30개</div>
                                            <div className={styles.xp1}>2,000 XP</div>
                                            {account.level==4 &&
                                                <div className={'buttonBadge'} style={{top:'-10px',left:'-12px', right:'auto', fontSize: '9px', padding: '3px 6px'}}>현재</div>
                                            }
                                      </div>
                                      <div className={account.level>=5 ? styles.div9 : styles.div5}>
                                            <div className={styles.tagpoint}>
                                                  <div className={styles.p}>Lv.5~</div>
                                            </div>
                                            <div className={styles.km}>무제한</div>
                                            <div className={styles.km}>~ 50개</div>
                                            <div className={styles.xp1}>레벨별 상이</div>
                                            {account.level>=5 &&
                                                <div className={'buttonBadge'} style={{top:'-10px',left:'-12px', right:'auto', fontSize: '9px', padding: '3px 6px'}}>현재</div>
                                            }
                                      </div>
                                </div>
                          </div>
                    </div>
                    <div className={styles.hedaerChild} />
                    <div className={styles.mainheaderGroup}>
                          <b className={styles.mainheader1}>
                                <span className={styles.mainheaderTxt}>
                                      <span>XP</span>
                                      <span className={styles.span}> 획득 방법</span>
                                </span>
                          </b>
                          <div className={styles.container}>
                                <div className={styles.div16}>
                                      <div className={styles.numbering}>
                                            <div className={styles.ai}>1</div>
                                      </div>
                                      <div className={styles.frameParent}>
                                            <div className={styles.aiParent}>
                                                  <div className={styles.ai}>인스타 AI 스크랩</div>
                                                  <div className={styles.tagpoint5}>
                                                        <div className={styles.p}>100XP</div>
                                                  </div>
                                            </div>
                                            <div className={styles.xpParent}>
                                                  <div className={styles.xpContainer}>
                                                        <span>{`· 친구 태그 시 `}</span>
                                                        <span className={styles.xp6}>50XP 추가 획득!</span>
                                                  </div>
                                                  <div className={styles.xpContainer}>
                                                        <span>{`· 태그된 친구도 `}</span>
                                                        <span className={styles.xp6}>50XP 획득!</span>
                                                  </div>
                                            </div>
                                      </div>
                                </div>
                                <div className={styles.div16}>
                                      <div className={styles.numbering}>
                                            <div className={styles.ai}>2</div>
                                      </div>
                                      <div className={styles.text}>
                                            <div className={styles.aiParent}>
                                                  <div className={styles.ai}>핫플 검토</div>
                                                  <div className={styles.tagpoint5}>
                                                        <div className={styles.p}>50XP</div>
                                                  </div>
                                            </div>
                                      </div>
                                </div>
                                <div className={styles.div16}>
                                      <div className={styles.numbering}>
                                            <div className={styles.ai}>3</div>
                                      </div>
                                      <div className={styles.frameParent}>
                                            <div className={styles.aiParent}>
                                                  <div className={styles.ai}>HOTPLE 친구 초대</div>
                                                  <div className={styles.tagpoint5}>
                                                        <div className={styles.p}>2,000XP</div>
                                                  </div>
                                            </div>
                                            <div className={styles.xpParent}>
                                                  <div className={styles.xpContainer}>
                                                        <span>{`· 태그한 친구가 튜토리얼 완료 시 `}</span>
                                                        <span className={styles.xp6}>2,000XP 획득!</span>
                                                  </div>
                                                  <div className={styles.xpContainer}>
                                                        <span>{`· 튜토리얼 완료한 친구도 `}</span>
                                                        <span className={styles.xp6}>1,000XP 획득!</span>
                                                  </div>
                                            </div>
                                      </div>
                                </div>
                          </div>
                    </div>
              </div>
        </div>
    );
};

export default LevelModal;