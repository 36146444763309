import React, { useRef, useState } from 'react';
import styles from './OnbaordPage.module.css';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const OnbaordPage = ({setNeedOnbaord}) => {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        infinite: false,
        speed: 200,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex), // 슬라이드가 바뀌기 직전에 인디케이터 변경
    };

    const handleStartButtonClicked = () => {
        localStorage.setItem('finishOnbaord', true);
        setNeedOnbaord(false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.contents}>
                    <div className={styles.skipLayout}>
                        <span className={styles.skipText} onClick={()=>sliderRef.current.slickGoTo(4)}>
                            {currentSlide != 4 && "Skip"}
                        </span>
                    </div>

                    <Slider ref={sliderRef} {...settings} className={styles.usageSlider}>
                        <img 
                            className={styles.contentImage} 
                            alt="" 
                            src="https://hotple.s3.ap-northeast-2.amazonaws.com/onboard/1.png"
                        />
                        <img 
                            className={styles.contentImage} 
                            alt="" 
                            src="https://hotple.s3.ap-northeast-2.amazonaws.com/onboard/2.png"
                        />
                        <img 
                            className={styles.contentImage} 
                            alt="" 
                            src="https://hotple.s3.ap-northeast-2.amazonaws.com/onboard/3.png"
                        />
                        <img 
                            className={styles.contentImage} 
                            alt="" 
                            src="https://hotple.s3.ap-northeast-2.amazonaws.com/onboard/4.png"
                        />
                        <img 
                            className={styles.contentImage} 
                            alt="" 
                            src="https://hotple.s3.ap-northeast-2.amazonaws.com/onboard/5.png"
                        />
                    </Slider>

                    <div className={styles.indicators}>
                        {currentSlide !== 4 ? (
                            <>
                                <div className={styles.cancleButton} onClick={() => sliderRef.current.slickPrev()}>
                                    {currentSlide !== 0 && <div className={styles.text}>◀</div>}
                                </div>
                                {[0, 1, 2, 3, 4].map((index) => (
                                    <div
                                        key={index}
                                        className={`${styles.indicator} ${currentSlide === index ? styles.activeIndicator : ''}`}
                                    ></div>
                                ))}
                                <div className={styles.deleteButton} onClick={() => sliderRef.current.slickNext()}>
                                    {currentSlide !== 4 && <div className={styles.text}>▶</div>}
                                </div>
                            </>
                        ) : (
                            <div className={styles.positiveButton} onClick={handleStartButtonClicked}>
                                <div className={styles.text1}>시작하기</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnbaordPage;
