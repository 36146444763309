import styles from './SettingAccountModal.module.css';


const SettingAccountModal = ({setInfoModalPage, setAuthModalPage, setModalProps, account, fcmToken}) => {
      const handleLogoutButtonClicked = async () => {
		setModalProps({fcmToken: fcmToken});
		setAuthModalPage(4);
      };

      const handleConnectInstagram = async () => {
		setAuthModalPage(3);
	}

      const handleDeleteAccount = async () => {
		setAuthModalPage(5);
	}

    return (
        <div className={styles.reviewmodal2}>
              <div className={styles.loginframe}>
                    <div className={styles.headerframeParent}>
                          <div className={styles.headerframe}>
                                <div className={styles.div}>계정 설정</div>
                          </div>
                          <div className={styles.contentsframe}>
                                {/*div className={styles.captionframe}>
                                      <div className={styles.div1}>비밀번호 변경</div>
                                      <img className={styles.iconbackAndroid} alt="" src="images/back_android.svg" />
                                </div>*/}
                                <div className={styles.captionframe} onClick={handleConnectInstagram}>
                                      <div className={styles.div1}>{account.instagramUsername==null ? "인스타그램 ID 연결": "연결된 인스타그램 ID 변경"}</div>
                                      <img className={styles.iconbackAndroid} alt="" src="images/back_android.svg" />
                                </div>
                                <div className={styles.captionframe1} onClick={handleLogoutButtonClicked}>
                                      <div className={styles.div1}>로그아웃</div>
                                      <img className={styles.iconbackAndroid} alt="" src="images/back_android.svg" />
                                </div>
                          </div>
                    </div>
                    <div className={styles.captionframe3} onClick={handleDeleteAccount}>
                          <div className={styles.div4}>회원 탈퇴</div>
                    </div>
              </div>
        </div>
    );
};

export default SettingAccountModal;