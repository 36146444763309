import { useEffect, useState } from 'react';
import styles from './AIScrapTutorialModal.module.css';
import api from '../../../../../api';

const AIScrapTutorialModal = ({devicePosition, myHotples, setInfoModalPage, setAuthModalPage, account}) => {
      const [hotple, setHotple] = useState({hotple_id:0, region:'', name:''});

      const callGetAIScrapUrl = async () => {
		try {
			const response = await api.get('/hotple/tutorial', {
                        params: {
                              lat: devicePosition.lat,
                              long: devicePosition.long
			      }
                  });

                  if(response)
                        setHotple(response.data.hotples[0]);
		} catch (error) {
			console.error('There was an error callGetAIScrapUrl!', error);
		}
	};

      const handleGoButtonClick = async () => {
            try {
                alert('댓글로 @hotple_kr 만 태그하면 AI 스크랩 완료😊');

                window.open(hotple.media_url, '_blank');
            } catch (error) {
                console.error('Error handling go button click:', error);
            }
      };

      useEffect(()=>{
            callGetAIScrapUrl();
      },[]);

      useEffect(()=>{
            if(myHotples.hotples.some(myHotple => myHotple.hotple_id==hotple.hotple_id))
                  setInfoModalPage(0);
      },[myHotples]);

      return (
            <div className={styles.container}>
                  <div className={styles.header}>
                        <div className={styles.contents}>
                              <b className={styles.title}>인스타 AI 스크랩 튜토리얼</b>
                              <div className={styles.innerContents}>
                                    <div className={styles.description}>
                                          <p className={styles.hotpleDm}>
                                                <b className={styles.hotple}>1. 예시 인스타 게시글로 이동</b>
                                                <span className={styles.span}> 한 뒤,</span>
                                          </p>
                                          <p className={styles.a}>
                                                <b className={styles.a1}>2. 댓글로 @hotple_kr 을 태그</b>
                                                <span className={styles.span1}> 하면 끝!</span>
                                          </p>
                                    </div>
                                    <video
                                          className={styles.description1}
                                          src="https://hotple.s3.ap-northeast-2.amazonaws.com/usage/ai_scrap.mp4"
                                          autoPlay 
                                          loop 
                                          muted 
                                          playsInline
                                    />
                                    
                                    {account.hotpleId ?
                                          account.instagramUsername ?
                                                <div className={styles.dmButton} onClick={() => handleGoButtonClick()}>
                                                      <div className={styles.text}>예시 인스타 게시글 이동</div>
                                                      <div className={styles.text1}>[{hotple.region}/{hotple.main_category}] {hotple.name}</div>
                                                </div>
                                                :
                                                <div className={styles.connectButtonFrame}>
                                                      <div className={styles.pickContainer}>
                                                            <p className={styles.pick}>인스타 AI 스크랩 기능을 이용하기 위해선</p>
                                                            <p className={styles.pick}>인스타그램 계정 연결이 필요해요!</p>
                                                      </div>
                                                      <div className={styles.connectinstagrambutton2} onClick={()=>setAuthModalPage(3)}>
                                                            <img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
                                                            <div className={styles.text}>Instagram 계정 연결</div>
                                                      </div>
                                                </div>
                                          :
                                          <div className={styles.connectButtonFrame}>
                                                <div className={styles.pickContainer}>
                                                      <p className={styles.pick}>인스타 AI 스크랩 기능을 이용하기 위해</p>
                                                      <p className={styles.pick}>먼저 HOTPLE에 로그인 해주세요!</p>
                                                </div>
                                                <div className={styles.buttondefault}  onClick={()=>setAuthModalPage(1)}>
                                                      <img className={styles.iconfire1} alt="" src="images/fire.svg" />
                                                      <div className={styles.text}>HOTPLE 로그인</div>
                                                </div>
                                          </div>
                                    }
                              </div>
                        </div>
                  </div>
            </div>
      );
};

export default AIScrapTutorialModal;