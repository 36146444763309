import api from '../../../../api';
import styles from './DeleteAccount.module.css';


const DeleteAccount = ({setAuthModalPage, setInfoModalPage, setAccount, marginTop}) => {
    const handleDeleteAccount = async () => {
        const flag = window.confirm("회원 탈퇴 시, 데이터는 즉시 영구삭제되며 복구 불가능합니다.\n\n정말로 탈퇴하시겠어요?");

        if(flag){
            setAuthModalPage(0);
            setInfoModalPage(0);
            try{
                await api.post(`/auth/delete_account/`);
            }catch(error){
                console.log(error);
            }
            setAccount({hotpleId:null, instagramUsername:null, level:0, xp:{total_xp:0, level_xp:0, level_max:500}, tutorial_phase:1});
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        }
    };

    return (
        <div className={styles.reviewmodal2} style={{maxHeight: `calc(100dvh - ${marginTop + 24}px)`, marginTop: `${marginTop}px`}}>
            <div className={styles.loginframe}>
                <div className={styles.headerframeParent}>
                    <div className={styles.headerframe}>
                        <b className={styles.b}>떠나신다니 너무 아쉬워요 😢</b>
                    </div>
                    <div className={styles.parent}>
                        <div className={styles.div}>
                            <span>{`회원 탈퇴 시, `}</span>
                            <span className={styles.span}>아래 정보들이 영구 삭제돼요..!</span>
                        </div>
                        <div className={styles.idHotplePickContainer}>
                            <ul className={styles.idHotplePick}>
                                <li className={styles.li}>핫플 계정 인증정보</li>
                                <li className={styles.li}>연결 인스타그램 ID</li>
                                <li>HOTPLE에서 PICK한 핫플 리스트</li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.div1}>
                        <p className={styles.p}>매달 색다른 기능들을 보여드릴 예정이니</p>
                        <p className={styles.p}>조금만 더 지켜봐주시면 안될까요?</p>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <div className={styles.cancleButton} onClick={()=>setAuthModalPage(0)}>
                        <div className={styles.text}>조금만 더 지켜볼게요</div>
                    </div>
                    <div className={styles.deleteButton} onClick={()=>handleDeleteAccount()}>
                        <div className={styles.text}>탈퇴할래요</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteAccount;