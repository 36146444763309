import { isMobile } from 'react-device-detect';
import styles from './HotpleItem.module.css';
import LazyLoad from 'react-lazyload';
import { copyToClipboard } from '../../../../../../util';


const HotpleItem = ({hotple, activeMenu, updateHotpleInfo, setSelectedHotple, isSelected}) => {
	if (!hotple)
        return null;

	const patchIsPick = () => {
		const updatedHotple = {
			...hotple,
			is_pick: !hotple.is_pick,
			mention_count: hotple.mention_count + (!hotple.is_pick ? 1 : -1)
		};

		updateHotpleInfo('updateIsPick', updatedHotple);
	};

	const whenHotpleClicked = () =>{
		
		if(!isSelected){
			setSelectedHotple(null);
			setTimeout(() => {
				setSelectedHotple(hotple);
			}, 50);
		}
	};

	const whenImageEditButtonClicked = () => {
		updateHotpleInfo('showImageEditHotpleModal', hotple);
	};

	const whenRightButtonClicked = () => {
		if(hotple.need_verify)
			updateHotpleInfo('showVerifyHotpleModal', hotple);
		else
			updateHotpleInfo('showEditHotpleModal', hotple);
	};

	function handleVerifyResultButtonClicked() {
		updateHotpleInfo('showEditRequestModal', hotple);
	}

  	return (
		<div className={(activeMenu=='my' || activeMenu=='admin') ? styles.myHotpleItem : styles.nearbyHotpleItem} onClick={whenHotpleClicked} data-status={isMobile? false : isSelected}>
			{(activeMenu=='my' || activeMenu=='admin') &&
				<div className={styles.iconcloseLineParent}>
					{activeMenu=='admin' &&
					<>
						<span className={styles.idText} onClick={(event)=>{event.stopPropagation();copyToClipboard(hotple.hotple_id);}}>{hotple.hotple_id}:</span>
						<span className={styles.mediaText} onClick={(event)=>{event.stopPropagation();copyToClipboard(hotple.media_id);}}>{hotple.media_id}</span>
					</>
					}
					<img className={styles.iconcloseLine} onClick={(event)=> {event.stopPropagation(); updateHotpleInfo((activeMenu=='admin'?"showDeleteHotpleModal":'showDeleteMyHotpleModal'),hotple);}} alt="" src="images/close_line.svg" />
				</div>
			}

			{activeMenu == 'admin' && hotple.verify_results && hotple.verify_results.filter(verify_result => verify_result.need_check).length>0 &&
				<div className={styles.editrequestframe}>
					<div className={styles.editrequesttitle}>수정 요청:</div>
					{hotple.verify_results
						.filter(verify_result => verify_result.need_check) // need_check가 true인 객체만 필터링
						.map((verify_result, index) => (
							<div 
								key={index} 
								className={styles.editrequstbutton} 
								onClick={() => handleVerifyResultButtonClicked(verify_result)} // 버튼 클릭 시 함수 호출
								>
								<div className={styles.editrequestnumber}>{index + 1}</div> {/* 인덱싱하여 1부터 표시 */}
							</div>
						))
					}
				</div>
			}

			<div className={styles.imageframe}>
				<div className={styles.image1frame} onClick={whenHotpleClicked}>
					<LazyLoad height={152} offset={333} once scrollContainer="#hotpleitems-container">
						<img className={styles.image1Icon} alt="" src={hotple.contents_url && hotple.contents_url[0] ? (hotple.contents_url[0].includes("mp4") ? (hotple.preview_url || "images/no_instagram_image.svg") : hotple.contents_url[0]) : (hotple.preview_url || "images/no_instagram_image.svg")} />
					</LazyLoad>
					{hotple.media_url &&
					<>
						<div className={styles.infoframe}>
								<img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
								<div className={styles.username}>@{hotple.media_creator}</div>
						</div>
					</>
					}
				</div>

				{hotple.contents_url && hotple.contents_url.length > 1 &&
					<div className={styles.image2frame} onClick={whenHotpleClicked}>
						<LazyLoad height={152} offset={333} once scrollContainer="#hotpleitems-container">
							<img className={styles.image1Icon} alt="" src={hotple.contents_url[1]} />
						</LazyLoad>
						{hotple.media_url &&
						<>
							<div className={styles.infoframe}>
									<img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
									<div className={styles.username}>@{hotple.media_creator}</div>
							</div>
						</>
						}
					</div>
				}

				{hotple.contents_url && hotple.contents_url.length>2 ?
					<a className={styles.image2frame} href={hotple.media_url} target="_blank" rel="noopener noreferrer">
						<LazyLoad height={152} offset={333} once scrollContainer="#hotpleitems-container">
							<img className={styles.image1Icon} alt="" src={hotple.contents_url[2]} />
						</LazyLoad>
						{hotple.media_url &&
						<>
							<div className={styles.infoframe2}>
									<img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
									<div className={styles.username}>@{hotple.media_creator}</div>
							</div>
							
							<div className={styles.infos}>
								<b className={styles.imagenum}>+{hotple.contents_url.length-2}장</b>
								<div className={styles.div}>
									<span className={styles.txt}>
											<p className={styles.p}>게시글</p>
											<p className={styles.p}>보러가기</p>
									</span>
								</div>
							</div>
						</>
						}
					</a>
					:
					<a className={styles.image2frame} href={hotple.media_url} target="_blank" rel="noopener noreferrer">
						<LazyLoad height={152} offset={333} once scrollContainer="#hotpleitems-container">
							<img className={styles.image1Icon} alt="" src={hotple.contents_url && hotple.contents_url[1] ? (hotple.contents_url[1].includes("mp4") ? (hotple.preview_url || "images/no_instagram_image.svg") : hotple.contents_url[1]) : (hotple.preview_url || "images/no_instagram_image.svg")} />
						</LazyLoad>
						{hotple.media_url &&
						<>
							<div className={styles.infoframe2}>
									<img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
									<div className={styles.username}>@{hotple.media_creator}</div>
							</div>
						
							<div className={styles.infos}>
								<img className={styles.iconshare} alt="" src="images/share.svg" />
								<div className={styles.div}>
									<span className={styles.txt}>
											<p className={styles.p}>게시글</p>
											<p className={styles.p}>보러가기</p>
									</span>
								</div>
							</div>
						</>
						}
					</a>
				}

				{(!hotple.contents_url || hotple.contents_url.length <= 1) &&
					((!hotple.preview_url || !hotple.contents_url || hotple.contents_url.length==0) && activeMenu=='admin' ?
						<div className={styles.image2frame} onClick={whenImageEditButtonClicked}>
							<div className={styles.infoframe3}/>
							<div className={styles.infos}>
								<img className={styles.iconshare2} alt="" src="images/search.svg" />
								<div className={styles.div}>
									<span className={styles.txt2}>
											<p className={styles.p}>이미지</p>
											<p className={styles.p}>정보확인</p>
									</span>
								</div>
							</div>
						</div>
					:
						<div className={styles.image2frame} onClick={whenHotpleClicked}/>
					)
				}
			</div>
			<div className={styles.detailframe}>
					<div className={styles.detailleft}>
						<div className={styles.title}>
								<div className={styles.hotplename}>{hotple.name}</div>
								<div className={styles.ratingframe}>
									<img className={styles.iconfire} alt="" src="images/fire.svg" />
									<div className={styles.ratingtext}>{hotple.mention_count}명의 PICK!</div>
								</div>
						</div>
						<div className={styles.tags}>
								<div className={styles.tagplaceDetail}>
									<div className={styles.orange}>{hotple.main_category}</div>
								</div>
								<div className={styles.tagplaceDetail1}>
									<div className={styles.orange}>{hotple.sub_category2}</div>
								</div>
								{hotple.tags && hotple.tags.map((tag, index) => (
									<div className={styles.tagplaceDetail2} key={index + 1}>
										<div className={styles.orange}>#{tag}</div>
									</div>
								))}
						</div>
						<div className={styles.caption}>{hotple.caption}</div>
						{hotple.comment &&
							<div className={styles.commentframe}>	
									<div className={styles.label}>댓글</div>
									<div className={styles.comment}>
										{hotple.taggedBy!=null &&
											<a className={styles.taggedBy} href={`https://instagram.com/${hotple.taggedBy}`} target="_blank" rel="noopener noreferrer">@{hotple.taggedBy}: </a>
										}
										{hotple.comment.split(/(@[\w\.]+)/g).map((part, index) => {
											if (part.startsWith('@')) {
											// 유저 태그 부분 처리
											const username = part.slice(1); // '@' 제거한 유저 이름
											return (
												<a
												key={index}
												className={styles.commentUser}
												href={`https://instagram.com/${username}`}
												target="_blank"
												rel="noopener noreferrer"
												>
												{part}
												</a>
											);
											} else {
											// 일반 텍스트 처리
											return (
												<span key={index} className={styles.span}>
												{part}
												</span>
											);
											}
										})}
									</div>
							</div>
						}
						{hotple.has_period &&
							<div className={styles.commentframe}>
									<div className={styles.label}>기간</div>
									<div className={styles.period}>{hotple.start_date && hotple.start_date.replaceAll('-','/')} ~ {hotple.end_date && hotple.end_date.replaceAll('-','/')}</div>
							</div>
						}
					</div>
					{activeMenu=='search' || activeMenu=='home' ?
						<div className={styles.detailrightbutton} onClick={(event) => {event.stopPropagation(); patchIsPick();}}>
							{hotple.is_pick ?
								<img className={styles.iconlikeActive} alt="" src= "images/like_active.svg" />
								:
								<img className={styles.iconlikeOutlined} alt="" src= "images/like_outlined.svg" />
							}
							<div className={hotple.is_pick ? styles.is_pick : styles.not_pick}>PICK</div>
						</div>
					:
						<div className={styles.detailrightbutton} onClick={(event)=>{event.stopPropagation();whenRightButtonClicked();}}>
							{hotple.need_verify ?
								<img className={styles.iconlikeActive} alt="" src= "images/update.svg" />
								:
								<img className={styles.iconlikeOutlined} alt="" src= "images/pen.svg" />
							}
							<div className={hotple.need_verify ? styles.is_pick : styles.not_pick}> {hotple.need_verify ? "검토 필요" : "정보 수정"} </div>
						</div>
					}
			</div>
		</div>
	);
};

export default HotpleItem;
