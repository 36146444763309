import styles from './AuthModal.module.css';
import LoginModal from './Login/LoginModal';
import RegisterModal from './Register/RegisterModal';
import ConnectInstagramModal from './ConnectInstagram/ConnectInstagramModal';
import LogoutModal from './Logout/LogoutModal';
import DeleteAccount from './DeleteAccount/DeleteAccount';
import { useEffect, useState } from 'react';

const AuthModal = ({authModalPage, setAuthModalPage, setInfoModalPage, setUserModalPage, modalProps, setModalProps, getMyInfo, setAccount, account, fcmToken, platform, marginTop}) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
    }, []);
    
    const goPrevPage = () => {
        setAuthModalPage(authModalPage-1);
    };

    const goNextPage = () => {
        setAuthModalPage(authModalPage+1);
    }

    const handleCloseButton = async () => {
        if(authModalPage==3){
            getMyInfo();
            setAuthModalPage(0);
        }
        else
            setAuthModalPage(0);
    }

  	return (
        <div className={`${styles.modalbackground} ${visible ? styles.visible : ''}`}>
            <div className={`${styles.modalcontainer} ${visible ? styles.visible : ''}`}>
                {authModalPage==1 && <LoginModal setAuthModalPage={setAuthModalPage} getMyInfo={getMyInfo} marginTop={marginTop}/>}
                {authModalPage==2 && <RegisterModal setAuthModalPage={setAuthModalPage} setUserModalPage={setUserModalPage} setModalProps={setModalProps} getMyInfo={getMyInfo} fcmToken={fcmToken} platform={platform} marginTop={marginTop}/>}
                {authModalPage==3 && <ConnectInstagramModal setAuthModalPage={setAuthModalPage} account={account} marginTop={marginTop}/>}
                {authModalPage==4 && <LogoutModal setAuthModalPage={setAuthModalPage} setInfoModalPage={setInfoModalPage} setAccount={setAccount} fcmToken={modalProps.fcmToken} marginTop={marginTop}/>}
                {authModalPage==5 && <DeleteAccount setAuthModalPage={setAuthModalPage} setInfoModalPage={setInfoModalPage} setAccount={setAccount} marginTop={marginTop}/>}

                {authModalPage==11 && <RegisterModal setAuthModalPage={setAuthModalPage} setUserModalPage={setUserModalPage} setModalProps={setModalProps} getMyInfo={getMyInfo} fcmToken={fcmToken} platform={platform} marginTop={marginTop}/>}

                <div className={styles.closebutton} onClick={()=>handleCloseButton()} style={marginTop!=0 ? {top: `${marginTop+12}px`} : {}}>
                    <img className={styles.iconcloseLine} alt="" src="images/close_line.svg" />
                </div>
                {authModalPage==2 &&
                    <div className={styles.backbutton} onClick={goPrevPage} style={marginTop!=0 ? {top: `${marginTop+12}px`} : {}}>
                        <img className={styles.iconcloseLine} alt="" src="images/back.svg" />
                    </div>
                }
            </div>
        </div>
    );
};
    
export default AuthModal;
  