import styles from './LoginHeader.module.css';


const LoginHeader = ({ setAuthModalPage, setTutorialModalPage }) => {
  	return (
		<div className={styles.header}>
			<div className={styles.iconfireParent}>
					<img className={styles.iconfire} alt="" src="images/fire.svg" />
					<img className={styles.hotple1Icon} alt="" src="images/HOTPLE.svg" />
			</div>
			<div className={styles.pick}>HOTPLE이 처음이신가요?<br/>튜토리얼을 통해 활용법도 배우고, 보상도 GET!</div>
			<div className={styles.instagramFrame}>
				<div className={styles.connectinstagrambutton3}  onClick={()=>setTutorialModalPage(1)}>
					<div className={styles.text2}><span className={styles.iconfireParent2}>HOTPLE</span> 튜토리얼</div>
					<div className={styles.point}>+2500XP</div>
				</div>
				<div className='buttonBadge'>HOTPLE이 처음이라면!</div>
			</div>

			<div className={styles.buttondefault}  onClick={()=>setAuthModalPage(1)}>
					<img className={styles.iconfire1} alt="" src="images/fire.svg" />
					<div className={styles.text}>HOTPLE 로그인</div>
			</div>
			
		</div>);
};

export default LoginHeader;
