import { useEffect, useState } from 'react';
import styles from './FinishTutorialModal.module.css';
import api from '../../../../../api';
import { shareContent } from '../../../../../util';

const FinishTutorialModal = ({setInfoModalPage, account}) => {

      const handleInviteButton = async () => {
            await shareContent('HOTPLE 초대',`[HOTPLE 초대]\n@${account.instagramUsername} 님이 당신을 HOTPLE로 초대했어요💌 HOTPLE 튜토리얼을 완료하면, 나와 친구 둘 다 보상을 받을 수 있어요!\n\n지금 바로 친구와 함께 핫플 지도을 만들러 가볼까요? 😉\n\n인스타 핫플 찾을 땐, HOTPLE!\nhttps://hotple.today`);
            setInfoModalPage(0);
      };

      return (
            <div className={styles.container}>
                  <div className={styles.innerContents}>
                        <video
                              className={styles.description1}
                              src="https://hotple.s3.ap-northeast-2.amazonaws.com/usage/tutorial_finish.mp4"
                              autoPlay 
                              muted 
                              playsInline
                        />
                        
                        <div className={styles.buttons}>
                              <div className={styles.negativeButton} onClick={()=>setInfoModalPage(0)}>
                                    <div className={styles.text}>돌아가기</div>
                              </div>
                              <div className={styles.positiveButton} onClick={()=>handleInviteButton()}>
                                    <div className={styles.text1}>친구 초대 메시지 전송</div>
                              </div>
                        </div>
                  </div>
            </div>
      );
};

export default FinishTutorialModal;