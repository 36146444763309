import styles from './UserModal.module.css';
import DeleteHotpleModal from './DeleteHotple/DeleteHotpleModal';
import AddedHotpleModal from './AddedHotple/AddedHotpleModal';
import PermissionModal from './Permission/PermissionModal';
import LevelModal from './Level/LevelModal';
import { useEffect, useState } from 'react';
import TermModal from './Term/TermModal';


const UserModal = ({userModalPage, setUserModalPage, modalProps, myHotples, updateHotpleInfo, account, marginTop}) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
    }, []);

  	return (
        <div className={`${styles.modalbackground} ${visible ? styles.visible : ''}`}>
            <div className={`${styles.modalcontainer} ${visible ? styles.visible : ''}`}>
                {userModalPage==1 && <DeleteHotpleModal setUserModalPage={setUserModalPage} modalProps={modalProps} updateHotpleInfo={updateHotpleInfo} marginTop={marginTop}/>}
                {userModalPage==2 && <AddedHotpleModal setUserModalPage={setUserModalPage} modalProps={modalProps} myHotples={myHotples} updateHotpleInfo={updateHotpleInfo}/>}
                {userModalPage==3 && <PermissionModal setUserModalPage={setUserModalPage} modalProps={modalProps}/>}
                {userModalPage==4 && <LevelModal account={account} marginTop={marginTop}/>}
                {userModalPage==5 && <TermModal setUserModalPage={setUserModalPage} images={modalProps.images} />}

                {(userModalPage==1 || userModalPage==4) && 
                    <div className={styles.closebutton} onClick={()=>setUserModalPage(0)} style={marginTop!=0 ? {top: `${marginTop+12}px`} : {}}>
                        <img className={styles.iconcloseLine} alt="" src="images/close_line.svg" />
                    </div>
                }
            </div>
        </div>
    );
};
    
export default UserModal;